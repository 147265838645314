import sally from './sally.jpg'
import kareem from './kareem.jpg'
import terry from './terry.jpg'
import thomas from './thomas.jpg'
import carolin from './carolin.png'
import good from './good.jpg'
import ray from './ray.jpg'
import helen from './helen.jpg'
import defaultUser from './default-user.png'

export {
  sally, thomas, carolin, terry, kareem, good, ray, helen, defaultUser,
}
