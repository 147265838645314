export const ADD_JOB = 'ADD_JOB'
export const ADD_POST = 'ADD_POST'
export const DELETE_POST_STATUS = 'DELETE_POST_STATUS'
export const ADD_POST_COMMENT = 'ADD_POST_COMMENT'
export const LIKE_POST = 'LIKE_POST'
export const UNLIKE_POST = 'UNLIKE_POST'
export const CREATE_POST_COMMENT_START = 'CREATE_POST_COMMENT_START'
export const UPDATE_POST = 'UPDATE_POST'
export const DELETE_POST_COMMENT = 'DELETE_POST_COMMENT'
export const UPDATE_POST_COMMENT = 'UPDATE_POST_COMMENT'
export const POST_SIGNUP_EMPLOYER_PREVIOUS_DATA_FETCH = 'POST_SIGNUP_EMPLOYER_PREVIOUS_DATA_FETCH'
export const POST_SIGNUP_AGENT_PREVIOUS_DATA_FETCH = 'POST_SIGNUP_AGENT_PREVIOUS_DATA_FETCH'
export const ADD_GROUP = 'ADD_GROUP'
export const UPDATE_GROUP = 'UPDATE_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'

export const ADD_GROUP_TOPIC = 'ADD_GROUP_TOPIC'
export const UPDATE_GROUP_TOPIC = 'UPDATE_GROUP_TOPIC'
export const DELETE_GROUP_TOPIC = 'DELETE_GROUP_TOPIC'

export const UPDATE_TOPIC_STATS = 'UPDATE_TOPIC_STATS'

export const POST_TOPIC_COMMENT = 'POST_TOPIC_COMMENT'
export const UPDATE_TOPIC_COMMENT = 'UPDATE_POST_TOPIC_COMMENT'
export const DELETE_TOPIC_COMMENT = 'DELETE_TOPIC_COMMENT'

export const LOAD_MORE_COMMENTS = 'LOAD_MORE_COMMENTS'
export const ADD_COMMENT_TO_POST = 'ADD_COMMENT_TO_POST'
export const FETCH_COMMENT_FOR_POST = 'FETCH_COMMENT_FOR_POST'
export const SET_IS_COMMENT_LOADING = 'SET_IS_COMMENT_LOADING'
export const TOPIC_ACTIVITY = 'TOPIC_ACTIVITY'
export const JOB_FIELDS = 'JOB_FIELDS'
export const CREATE_NEW_POST = 'CREATE_NEW_POST'
export const POST_DATA_FETCH = 'POST_DATA_FETCH'
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS'
export const SET_TALENT_SKILL_TAGS = 'SET_TALENT_SKILL_TAGS'
export const GET_TALENT_SKILL_TAGS = 'GET_TALENT_SKILL_TAGS'
export const UPDATE_JOB = 'UPDATE_JOB'
export const DELETE_JOB = 'DELETE_JOB'
