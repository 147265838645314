import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import reducer from './redux'
import saga from './saga'

const sagaMiddleware = createSagaMiddleware()
const middleware = [ ...getDefaultMiddleware({ thunk: false }), sagaMiddleware ]

const store = configureStore({
  reducer,
  middleware,
})

sagaMiddleware.run(saga)

export default store
